.map_box {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    background: linear-gradient(90deg, #108edd 11%, #19191e 87%);
    display: flex;
}

@font-face {
    font-family: 'Publica Sans';
    src: local('Publica Sans'), url(../fonts/PublicaSans-Medium.otf) format('opentype');
    src: local('Publica Sans'), url(../fonts/PublicaSans-Medium.woff) format('woff');
}

@font-face {
    font-family: 'Publica Sans Light';
    src: local('Publica Sans'), url(../fonts/PublicaSans-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Campton';
    src: local('Campton'), url(../fonts/CamptonLight.otf) format('opentype');
    src: local('Campton'), url(../fonts/CamptonLight.woff) format('woff');
}

body {
    font-family: 'Campton' !important;
    // font-size: 16px;
    // color: #fff;
    // background-color: #19191e;
}
.wbsb-map {
    max-height: 90%;
    max-width: 50%;
}
.container-fluid {
    padding: 0;
}

.graph-wrapper {
    padding: 15px;
    @media screen and (min-width: 768px) {
        padding: 25px;
    }
}

.translateIcon {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.data-section {
    // max-height: 50px;
    // max-width: 90%;
    padding: 0 5%;
    @media screen and (max-width: 768px) {
        margin-top: 50px;
        padding: 0 0%;
    }
    // .dashbard {
    //     max-height: 600px;
    //     width: auto;
    // }
}
.country-select {
    // background-color: darkblue;
    color: white;
    margin: 0px 0px;
}

.country-option {
    color: white;
    background-color: #1976d2;

    border-radius: 10px 10px 0px 0px !important;
    box-shadow: none !important;
    padding: 10px 15px;
    margin-right: 5px !important;
}

.selected-country {
    color: black !important;
    background-color: white !important;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 15px;
    z-index: 0 !important;
    margin-right: 5px;
    //box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
}

.chip {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.question-drawer {
    @media screen and (max-width: 768px) {
        position: absolute;
        z-index: 100;
        opacity: 1;
        width: 70%;
        max-height: 126vh;
    }
    height: 100%;
    max-height: 1200px;
    overflow: scroll;
    // max-height: 100vh;
    // overflow-y: hidden;
}

.question-drawer::-webkit-scrollbar {
    display: none;
}

.body {
    height: 100vh;
    width: 100vw;
}

.footer-link {
    color: black;
    text-decoration: none;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.footer-link:hover {
    color: black;
    text-decoration: none;
}
.homepage-subbanner {
    @media screen and (max-width: 768px) {
    }
}

.spanText {
    margin: 20px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        margin: 10px;
        font-size: 20px;
    }
}

.homepage-banner {
    display: flex;
    justify-content: left;
    width: 100%;
    min-height: 450px;
    // background: rgb(24, 95, 175);
    background: linear-gradient(90deg, #0078b7, #0965c1);
    margin: 0 !important;
    span {
        color: white;
        font-size: 48px;
        // font-weight: 600;
        font-family: 'Publica Sans';
    }

    @media screen and (max-width: 992px) {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .banner-text {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 100px;
        padding-left: 25px;
        z-index: 10;
        .redirect-link {
            display: block;
            text-decoration: none;
            background-color: #044389;
            border: 2px solid #044389;
            transition: 0.4s;

            color: white !important;
            padding: 0.4em 1em;
            width: max-content;
        }
        .redirect-link:hover {
            text-decoration: none;
            background-color: white;
            color: #044389 !important;
            border: 2px solid #044389;
        }

        @media screen and (max-width: 992px) {
            text-align: center;
            justify-content: center;
            align-items: center;
            margin-bottom: 0px;
            padding-left: 0px;
            .redirect-link {
                margin-top: 0.5em;
            }
        }
    }

    .img-wrapper {
        max-width: 690px;
        display: flex;
        align-items: center;
        .banner-image {
            width: 100%;
            height: 100%;
            max-height: 300px;
            max-width: 600px;
            margin: 0 auto;
            padding: auto;
        }
    }
}

.swiper-pagination-bullet-active {
    background-color: #fff !important;
}

.publication-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 0%);
    margin-bottom: 1em;
    height: 100%;
    .publication-title {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 0.5em;
    }

    .publication-description {
        font-size: 1em;
        margin-bottom: 0.5em;
    }
    .publication-link {
        display: block;
        text-decoration: none;
        background-color: #185faf;
        border: 1px solid #185faf;
        color: white !important;
        padding: 0.5em 1em;
        width: max-content;
    }
    .publication-link:hover {
        text-decoration: none;
        background-color: white;
        color: #185faf !important;
    }
}

.hidden {
    opacity: 0;
    transition: all 1s;
    transform: translate3d(0, 0, 0);
}

.show {
    opacity: 1;
    transform: translate3d(0, -10px, 0);
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    color: #185faf;
    text-decoration: underline;
}

.homepage {
    background: linear-gradient(180deg, rgba(227, 242, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.homepage-header {
    background: #044389;
}

.section {
    margin: auto;
    margin-top: 90px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.section-title {
    @media screen and (max-width: 768px) {
        margin-left: 15px;
    }
}

.methodologyd1 {
    margin-right: -50px;
    text-align: right;
    @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
        padding: 5px;
        padding-bottom: 20px;
        text-align: justify;
    }
}

.methodologyd2 {
    margin-left: -50px;
    @media screen and (max-width: 768px) {
        margin-left: 0;
        padding: 5px;
        padding-bottom: 20px;
        text-align: justify;
    }
}

.partner-section {
    margin: auto;
    margin-top: 90px;
    justify-content: center;
    display: flex;
    align-items: center;

    //a nice light blue color
    // background: rgb(227, 242, 253);
    // background: linear-gradient(180deg, rgba(227, 242, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
    //light yellow background
    padding-top: 15px;
    padding-bottom: 30px;
    .partner-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 380px;
        // border: 1px solid #185faf;
        margin: 0 15px;
        padding: 20px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 0%);
        border-radius: 20px;
        background-color: white;
        .img-wrapper {
            // min-height: 120px;
            max-height: 140px;
            max-width: 85%;
            height: 180px;
            margin-bottom: 20px;
            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                border-radius: 10px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border-radius: 0px 0px 10px 10px;
            height: 100%;

            h3 {
                min-height: 90px;
                line-height: 30px;
            }
            p {
                text-align: justify;
            }
            .partner-link {
                border-radius: 7px;
                text-decoration: none;
                background-color: white;
                border: 2px solid #185faf;
                color: #185faf !important;
                padding: 0.5em 1em;
                transition: 0.4s;
                margin-top: auto;
                // align-self: flex-end;
            }
            .partner-link:hover {
                text-decoration: none;
                background-color: #185faf;
                color: white !important;
            }
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
    }
}

.donor-image {
    @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
        padding: 5px;
        padding-bottom: 20px;
        text-align: justify;
    }
}

.partner-link {
    border-radius: 7px;
    text-decoration: none;
    background-color: white;
    border: 2px solid #185faf;
    color: #185faf !important;
    padding: 0.5em 1em;
    transition: 0.4s;
    margin-top: auto;
    // align-self: flex-end;
}
.partner-link:hover {
    text-decoration: none;
    background-color: #185faf;
    color: white !important;
}

.year-select {
    background-color: transparent;
    padding: 16.5px 32px 16.5px 14px;
    border-radius: 4px;
    option {
        border: 1px solid #185faf;
        border-radius: 4px;
        margin-top: 15px;
    }
    //checked option
}
